@import "variables";
@import "mixins";
@import "themes";
@import './bootstrap-settings.scss';
@import './icons';
@import 'material-overide';
@import "duration-input";
@import "borders";
/* You can add global styles to this file, and also import other style files */
$dark-theme-input: map-get($dark-theme, input);
$light-theme-input: map-get($light-theme, input);

$light-theme-input-highlight: map-get($light-theme, input-highlight);
$dark-theme-input-highlight: map-get($dark-theme, input-highlight);
$light-theme-select: map-get($light-theme, input-select);
$dark-theme-select: map-get($dark-theme, input-select);

* {
  scrollbar-width: thin;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;

  @include generate-input();
  @include generate-scroll();
  @include generate-general();

  .dark-theme {

    h4 {
      font-size: 14px;
      font-weight: bold;
    }

    option {
      color: map-get($dark-theme-select, option-color);
      background-color: map-get($dark-theme-select, option-bg);
    }

    a {
      color: $blue-250;

      &:hover {
        color: #FFFFFF;
      }
    }

    sm-menu .btn {
      color: $blue-250;
    }

    sm-menu .btn:hover {
      color: $cloudy-blue-two;
    }

  }

  .light-theme {
    &.cdk-overlay-pane {
      background-color: transparent;
    }

    a {
      color: $purple;

      &:hover {
        color: $purple;
        text-decoration: underline;
      }
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
    }

    option {
      color: map-get($light-theme-select, option-color);
      background-color: map-get($light-theme-select, option-bg);
    }
  }
}

.sm-navbar {
  min-height: $nav-bar-height;
  background-color: $nav-bar-bg;
  //margin-left: $side-bar-close-width;
  position: absolute;
  top: 0;
  width: calc(100% - #{$side-bar-close-width}) //64px);
}

.container {
  padding: 0 !important;
}

.stick-to-corner {
  position: absolute;
  top: 0;

  z-index: $generic-base-z-index + 99;
  margin: 16px;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

}

.row {
  margin: 0 !important;
}

.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pallete-cursor {
  cursor: url(../../../../webapp-common/assets/icons/pallete-cursor.png) 6 0, pointer;
}

.cursor {
  &.pointer {
    cursor: pointer;
  }
}

.resize-none {
  resize: none !important;
}

.d-flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  &.gap {
    gap: 12px !important;
  }
}
